@import "~core/components/menu";

// Megamenu
.navbar-nav .megamenu {
    @include media-breakpoint-up(lg) {
        & > .dropdown-menu {
            .menu-subcategories {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
            }
        }
    }
}
