@import "~core/account/account";

// Dashboard pages
.account-page {
    .marketing-email-title {
        margin-bottom: 2px !important;
    }
    .card {
        .card-header h2, 
        .card-header h3, 
        .card-header h4 {
            font-weight: bold;
            font-size: 18px;
        }
    }

}